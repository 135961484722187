import { request } from './request'
//下单轮询
export function loopQuery(list) {
  return request({
    url: '/api/ulinkpay/loopQuery',
    method: 'POST',
    timeout: 3000,
    data: list,
  })
}
//扫码下单
export function qrcode(list) {
  return request({
    url: '/api/ulinkpay/qrcode',
    method: 'POST',
    data: list,
  })
}
//微信下单
export function wxPay(list) {
  return request({
    url: '/api/ulinkpay/wxPay',
    method: 'POST',
    data: list,
  })
}
