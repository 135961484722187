<template>
  <div class="physicalPay">
    <!-- 切换就诊人 -->
    <SwitchPeople />
    <template v-if="healthFeeList != 0">
      <div
        class="healthFeeList"
        v-for="(item, index) in healthFeeList"
        :key="index"
      >
        <div class="healthFeeItem">
          <div class="feeItemCh">项目名称：</div>
          <div class="feeItemContent">{{ item.health_type }}</div>
        </div>
        <div class="healthFeeItem">
          <div class="feeItemCh">交易时间：</div>
          <div class="feeItemContent">{{ item.trade_date }}</div>
        </div>
        <div class="healthFeeItem">
          <div class="feeItemCh">金额：</div>
          <div class="feeItemContent">{{ item.pay_amt }}元</div>
        </div>
        <van-button
          class="buttonColor"
          size="small"
          round
          block
          type="info"
          @click="payHandel(item.pay_amt, item.health_no)"
        >
          缴费
        </van-button>
      </div>
    </template>
    <van-empty v-else description="暂无体检缴费记录" />
  </div>
</template>

<script>
import {
  healthFeeInfo,
  saveTradeLog,
  updateLogFunTrade,
} from '@/network/service'
import { qrcode, loopQuery } from '@/network/payment'
import { times, Debounce } from '@/common/utils'
import SwitchPeople from '@/components/common/switchPeople/switchPeople'
import { mapState } from 'vuex'
export default {
  name: 'physicalPay',
  data() {
    return {
      healthFeeList: [],
    }
  },
  components: {
    SwitchPeople,
  },
  computed: {
    ...mapState(['cardList', 'seq']),
  },
  mounted() {
    this.getHealthFeeInfo()
  },
  methods: {
    async getHealthFeeInfo() {
      let res = await healthFeeInfo({
        id_no: this.$Base64.decode(this.cardList[0].idNo),
        name: this.cardList[0].name,
      })
      if (res.code == 0) {
        this.healthFeeList = res.data.root.body.detail
      } else {
        this.$toast(res.msg)
      }
    },
    //缴费
    payHandel: Debounce(async function(fee, healthNo) {
      let res = await qrcode({
        seq: this.seq,
        urlString: this.$urlString,
        appKey: '17b1a89be880001',
        appSecret: '33cb874e10bb409a84f71d29f49ca017',
        mchNo: 'lffbyy',
        billNo: this.cardList[0].openId + new Date().getTime(),
        accountId: '105000080629028',
        sceneId: 'zyzf',
        totalAmount: parseInt(Number(fee).toFixed(2) * 100),
        subject: '体检缴费',
        // appKey: '177146cb8f80001',
        // appSecret: '4fdb3405f7cc4554bd76ebd306d33fc5',
        // mchNo: 'jandar',
        // billNo: this.cardList[0].openId + new Date().getTime(),
        // accountId: '105000080628641 ',
        // sceneId: 'TEST',
        // totalAmount: "1",
        // subject: '缴费'
      })
      if (res.code == '0') {
        //更新流水号
        await updateLogFunTrade({
          seq: this.seq,
          finishFlag: '体检缴费',
          card_type: this.cardList[0].cardType,
          card_no: this.cardList[0].cardNo,
          idno: this.$Base64.decode(this.cardList[0].idNo),
          name: this.cardList[0].name,
        })
        //保存支付总订单表日志记录
        await saveTradeLog({
          seq: this.seq,
          stat: 'PAYING',
          total_charge_amt: Number(fee).toFixed(2),
          person_pay: Number(fee).toFixed(2),
          channel: '建行聚合支付',
          trade_no: res.data.trade_no,
          med_pay: '1',
          business_type: '体检缴费',
        })
        //下单轮询
        let outcome = await loopQuery({
          seq: this.seq,
          urlString: this.$urlString,
          mchNo: 'lffbyy',
          appKey: '17b1a89be880001',
          appSecret: '33cb874e10bb409a84f71d29f49ca017',
          id_no: this.$Base64.decode(this.cardList[0].idNo),
          name: this.cardList[0].name,
          card_no: this.cardList[0].cardNo,
          health_no: healthNo,
          pay_amt: Number(fee).toFixed(2),
          pay_type: 3,
          treat_date: times().split(' ')[0],
          trade_time: times(),
          trade_no: res.data.trade_no,
          tradeNo: res.data.trade_no,
          operate_no: 'WXGZH',
          source: '体检缴费',
          refundReason: '业务失败退还',
          // mchNo: 'jandar',
          // appKey: '177146cb8f80001',
          // appSecret: '4fdb3405f7cc4554bd76ebd306d33fc5',
        })
        if (outcome.code == 0) {
          //调用下单页面
          window.location.href = res.data.codeUrl
        } else {
          this.$toast(res.msg)
        }
      } else {
        this.$toast(res.msg)
      }
    }, 1500),
  },
}
</script>

<style lang="less" scoped>
.physicalPay {
  width: 100vw;
  height: 100vh;
  background: #f5f5f5;
  .healthFeeList {
    width: 95%;
    margin: auto;
    padding: 0.7rem 0.5rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #fff;
    .healthFeeItem {
      height: 1.5rem;
      line-height: 1.5rem;
      display: flex;
      .feeItemCh {
        flex: 0.25;
      }
      .feeItemContent {
        flex: 0.75;
      }
    }
  }
  .healthFeeList:nth-child(2) {
    margin-top: 0.5rem;
  }
  .buttonColor {
    width: 15%;
    margin: 0 0 0 auto;
    background: @darkColor;
    border-color: @darkColor;
  }
}
</style>
