<template>
  <!-- 更换就诊人 -->
  <div class="user">
    <!-- 头像姓名 -->
    <div class="user-head">
      <van-popover
        placement="bottom-start"
        v-model="showPopover"
        trigger="click"
      >
        <span style="line-height: 2rem;padding: 0 5px;">病案号：{{$store.state.cardList[0].cardNo}}</span>
        <template #reference>
          <span
            style="color:#4facfe"
            v-if="$store.state.cardList.length > 0? true:false"
          >{{$store.state.nameState}}</span>
          <span v-else>未登录</span>
        </template>
      </van-popover>
    </div>
    <!-- 切换 -->
    <div
      class="user-switch"
      @click="$router.push('/user-data')"
    >
      <span style="color:#8d8fa1">更换就诊人</span>
      <span>
        <svg
          class="icon"
          style="font-size:1.2rem"
          aria-hidden="true"
        >
          <use xlink:href='#icon-youjiantou1-copy'></use>
        </svg>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      showPopover: false,
    }
  }
}
</script>
<style lang='less' scoped>
.user {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3rem;
  font-size: 1rem;
  background-color: rgba(254, 254, 254);
  border-bottom: 1px solid @lineColor;
  .user-head {
    display: flex;
    align-items: center;
    flex: 0.6;
    margin-left: 1rem;
    span {
      margin-left: 1rem;
      color: #8d8fa1;
    }
  }
  .user-switch {
    display: flex;
    justify-content: flex-end;
    flex: 0.4;
    margin-right: 1rem;
    span {
      float: right;
      color: @darkColor;
      margin-right: 0.5rem;
    }
  }
}
</style>